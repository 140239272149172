<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Approval queue
			</div>
		</div>

		<div>
			<card-view
				:cards="getCards"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<script>
	import CardView    from '@/components/ui/views/cardView/CardView';
	import api         from '@/services/api';

	export default {
		layout: 'v2default',

		metaInfo: {
			title: 'Approval queue'
		},
		components: {
			CardView
		},
		data: () => ({
			courses: [],
			searchString: '',
			pagination: {
				perPage: 			20,
				currentPage: 	1,
				totalPages: 	0
			},
			totalItems: 0
		}),
		computed: {
			getCourses () {
				if (!this.courses ||
						!this.courses.length) {
					return false;
				}
				return this.courses;
			},
			getCards () {
				if (!this.getCourses) {
					return [];
				};
				return this.getCourses.map((course) => {
					return {
						route: this.getCourseRoute(course),
						course
					};
				});
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/queue',
						text: 'Approval queue'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setCourses();
		},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setCourses();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},
			getCourseRoute (course) {
				return `/admin/courses/${course.id}`;
			},
			async setCourses () {
				const response = await api.admin.getCourses({
					include:       'metadata',
					searchString:  this.searchString,
					approval:      'pending',
					page:          this.pagination.currentPage,
					per:           this.pagination.perPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.courses = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-element='text'] {
		font-size:rem(12);
		color:$c-text;
		margin: 0 0 20px 0;
	}

  .page__info {
    margin-bottom: 24px;
  }
</style>
